.card {
  width: 25em;
  border: 1px solid #ccc;
  padding: 1em;
  border-radius: 5px;
}

.card a {
  text-decoration: none;
  line-height: 1.5;
}

.card h4 {
  font-family: "Montserrat", sans-serif;
  color: #000;
  margin-bottom: 0.5em;
}

.card:hover.card h4 {
  text-decoration: underline;
}

.card p {
  font-family: "Poppins", sans-serif;
  color: #333;
}

.img_box {
  width: 100%;
  height: 16.7em;
  /* margin-bottom: 0.8em; */
}

.img_box img {
  width: 100%;
  border-radius: 2px;
  /* object-fit: cover; */
}

@media screen and (max-width: 1295px) {
  .card {
    width: 20em;
  }

  .img_box {
    height: 12.7em;
  }
}

@media screen and (max-width: 1044px) {
  .card {
    width: 25em;
  }

  .img_box {
    height: 16.7em;
  }
}

@media screen and (max-width: 860px) {
  .card {
    width: 100%;
  }

  .img_box {
    height: 16em;
  }
}

@media screen and (max-width: 690px) {
  .card {
    width: 18em;
  }

  .img_box {
    height: 11.7em;
  }
}

@media screen and (max-width: 621px) {
  .card {
    width: 20em;
  }

  .img_box {
    height: 12.7em;
  }
}

@media screen and (max-width: 450px) {
  .card {
    width: 100%;
  }

  .img_box {
    height: 16.8em;
  }
}

@media screen and (max-width: 414px) {
  .img_box {
    height: 15.8em;
  }
}

@media screen and (max-width: 375px) {
  .img_box {
    height: 14em;
  }
}
