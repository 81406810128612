.container {
  width: 85%;
  margin: 0 auto;
  padding: 2em 0;
}

.container h1 {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  margin: 0.5em 0 1.5em 0;
  font-size: 2.5rem;
  letter-spacing: 3px;
}

.cards_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1em;
  place-items: center;
}

@media screen and (max-width: 1340px) {
  .container {
    width: 95%;
  }
}

@media screen and (max-width: 1044px) {
  .cards_container {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 621px) {
  .container {
    display: grid;
  }
  .cards_container {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 375px) {
  .container h1 {
    font-size: 2rem;
  }
}

/* End of the file */
