@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scrollbar-width: thin;
  scrollbar-color: #000 #ccc;
  scrollbar-gutter: stable;
}

html::-webkit-scrollbar {
  width: 0.5vw;
  scrollbar-gutter: stable;
}

html::-webkit-scrollbar-gutter {
  background-color: #ccc;
}

html::-webkit-scrollbar-thumb {
  background-color: #000;
}
