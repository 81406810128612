.container {
  width: 90%;
  margin: 0 auto;
  border-bottom: 1px solid #ccc;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em 0;
  font-family: "Montserrat", sans-serif;
}

.navbar a {
  text-decoration: none;
  color: #000;
}

.nav_list {
  display: flex;
  align-items: center;
  gap: 1.8em;
  list-style-type: none;
}

.nav_list li a {
  text-decoration: none;
  font-size: 1.1rem;
  color: #333;
  position: relative;
}

/* navbar li a animation */

.nav_list li a::before {
  content: "";
  position: absolute;
  top: 110%;
  width: 0;
  height: 2px;
  background: #333;
  transition: all 0.2s ease;
}

.nav_list li a:hover::before {
  width: 100%;
}

.logo {
  letter-spacing: 3px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
}

.navbar .menu_icon {
  display: none;
}

@media screen and (max-width: 600px) {
  .navbar {
    position: relative;
  }

  .nav_list {
    position: absolute;
    top: 100%;
    right: 0;
    flex-direction: column;
    gap: 0;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    clip-path: circle(0% at top right);
    transition: all 2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  }

  .nav_list.active {
    display: block;
    clip-path: circle(215% at top right);
  }

  .navbar .menu_icon {
    display: block;
    font-size: 1.5em;
    cursor: pointer;
  }

  .nav_list li {
    width: 100%;
  }

  .nav_list li a {
    display: block;
    width: 100%;
    text-align: center;
    padding: 1em;
    letter-spacing: 3px;
  }

  .nav_list li a::before {
    height: 0;
  }
}
